@import "../../utils/includes.scss";

//
// Fonts
//

$fontFamily: 'Nobel-Book', sans-serif;
$boldFontFamily: 'Nobel-Book', sans-serif;
$boldFontWeight: 700;
$titleFontFamily: $boldFontFamily;
$titleFontWeight: $boldFontWeight;
$isUppercase: uppercase;

//
// Colors
//

$textColor: #000000;
$linkColor: #000000;
$labelColor: #434644;
$formColor: #434644;
$utilColor: #000000;
$priceColor: #000000;
$lightColor: #ffffff;
$btnColorAlpha: #ffffff;
$btnColorBeta: #ffffff;
$btnColorStep: #ffffff;
$titleColor: #000000;
$titleSecondaryColor: #ffffff;
$subtitleColor: #000000;
$subtitleSecondaryColor: #ffffff;
$successColor: #2DB253;
$warningColor: #f7941d;
$errorColor: #ff3526;

$bgPrimaryColor: #ffffff;
$bgSecondaryColor: #f2f2f2;

$bgBtnAlpha: #000000;
$colorBtnAlpha: #ffffff;
$bgBtnBeta: #ffffff;
$colorBtnBeta: #000000;
$colorBtnOutlineAlpha: #000000;
$colorBtnOutlineBeta: #434644;
$paddingButtonPrimary: 16px 14px;
$paddingButtonSecondary: 11px 15px;
$borderRadiusButton: 0px;

$borderRadiusForm: 0px;
$borderColorForm: #000;
$borderSizeForm: 1px solid;

$calculatorTabsColor: #434644;
$calculatorTabsTextColor: #ffffff;
$calculatorCloseTabs: #434644;
$calculatorCloseTabsColor: #ffffff;
$calculatorMainColor: #ffffff;
$calculatorButton: #B9C0C2;

$garageBackground: #ffffff;
$garageTextColor: #000000;
$garageCarNoteColor: #B9C0C2;

$bgTopbarLogin: #434644;
$colorTopbarLogin: #ffffff;

$progressBarTitleBG: #B9C0C2;
$progressBarTitleColor: #ffffff;
$progressBarTitleCloseBG: #ffffff;
$progressBarTitleCloseColor: #B9C0C2;
$progressBarCloseButtonBG: #000000 ;
$progressBarCloseButtonColor: #ffffff;

$paymentCalculatorClosedBG: #000000;
$paymentCalculatorClosedColor: #ffffff;
$paymentCalculatorPanelBG: #000000;
$paymentCalculatorTermWrapperBG: #434644;
$paymentCalculatorTermWrapperColor: #ffffff;
$paymentCalculatorTermSelectedWrapperBG: #ffffff;
$paymentCalculatorTermSelectedColor: #ffffff;
$paymentCalculatorTermBG: #ffffff;
$paymentCalculatorTermColor: #000000;

// ========
// Styles
// ========
@include breakpoint(mobile){
    .widget-sr{
        &.makes__lexus{
            .LoginBanner .login-content{
                padding-right: 0;
            }
            .vehicle-tile-rebate-single{
                background: $bgBtnBeta;
                .is-price{
                    color: $colorBtnAlpha;
                }
                span{
                    color: $colorBtnAlpha;
                }
            }
        }
    }
}
.widget-sr{
    @include clearfix;
    width: 100%;

    &.makes__lexus{
        font-family: $fontFamily;
        font-size: 14px;
        color: $textColor;
        background-color: $bgPrimaryColor;
        &.Checkout{
            background-color: $bgSecondaryColor
        }
        .TransactionSummaryUnit--single{
            &:nth-child(1){
                background-color: #ffffff !important;
                border-bottom-color: #ffffff !important;
            }
            &:nth-child(2){
                background-color: #e6e6e6 !important;
                border-bottom-color: #e6e6e6 !important;
            }
            &:nth-child(3){
                background-color: #cccccc !important;
                border-bottom-color: #cccccc !important;
            }
        }
        .TransactionSummaryUnit--single:first-child:before{
            border-left-color: #ffffff !important;
        }
        .TransactionSummaryUnit--single:nth-child(2):before{
            border-left-color: #e6e6e6 !important;
        }
        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper .TransactionSummaryUnit--single.is-validate{
            border-bottom-color: #2db253!important;
        }
        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper .TransactionSummaryUnit--single.is-validate .wrapper-icon img{
            filter: none;
        }
        .sr-button-2{
            color: $colorBtnBeta !important;
            border: 1px solid #000000 !important;
        }
        .sr-topbar-login{
            padding: 0;
        }
        .dropdown-content.sr-topbar-login{
            padding: 11px 14px !important;
        }
        #button-register.sr-button-1{
            background: $progressBarTitleBG;
        }
        .vehicle-tile-rebate-single,
        .vehicle-item .vehicle-description .vehicle-name,
        .vehicle-item .vehicle-description .vehicle-cost,
        .vehicle-item .vehicle-description .vehicle-cost .vehicle-price,
        .vehicle-item .vehicle-description .vehicle-cost .vehicle-price-total,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .taxes-detail,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-amount .vehicle-payment-amount-note,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-rate,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-rate .vehicle-payment-rate-note,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-cashdown,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-cost-credit,
        .vehicle-item .vehicle-description .vehicle-name .vehicle-body,
        .vehicle-item .vehicle-payment .vehicle-payment-title,
        .vehicle-tile .sr-link,
        .listing-tile-note .sr-link
        {
            color: $labelColor;
        }
        .purchase-methods-cash .purchase-detail-line .value.is-util{
            color: $lightColor;
        }
        .method-button-payment-bar button:after,
        .listing-used-filters-head .listing-used-filters-head-button p.current-filter:after{
            background-color: $utilColor;
        }
        .section-catalog-filter .catalog-filter-selection .catalog-filter-type .catalog-filter-type-list--single.selected,
        .listing-used-results .number-results,
        .listing-tile .listing-tile-all-price,
        .listing-used-filters-head .listing-used-filters-head-button p a,
        .listing-used-filters-head .listing-used-filters-head-title,
        .listing-used-filters .listing-used-filters-bloc .listing-used-filters-title,
        .method-button-payment-bar button{
            color: $textColor;
        }
        .listing-used-filters .slider-labels,
        .listing-used-payment-search .input-search input{
            color: $labelColor;
        }
        .listing-used-filters .rc-slider .rc-slider-handle{
            border-color: $labelColor;
        }
        .listing-tile .listing-tile-car-save.has-warning-background{
            background-color: $labelColor;
        }
        .vehicle-note .VehicleDisclaimersContent p{
            color: $textColor;
        }
        .listing-tile .listing-tile-image .car-tagline{
            color: $lightColor;
        }
        .listing-tile .listing-tile-all-price .disclaimer-price.is-light{
            color: $labelColor;
        }
        .showroom-vdp-used-car-info .car-number.is-light{
            color: $labelColor;
        }
        .carfax-list.carfax-content .carfax-items-list .is-light{
            color: $labelColor;
        }
        .carfax-logo p.is-light{
            color: $labelColor;
        }
        .tile-payment-info .payment-rebates .payment-rebate{
            color: $labelColor;
        }
        .vehicleBanner-photos .vehicleBanner-photo-button{
            background: rgba(255,255,255,1);
            &:hover{
                background: rgba(255,255,255,0.85);
            }
        }
        .vehicleBanner-photos .vehicleBanner-photo-button icon{
            color: $utilColor;
        }
        .vehicleBanner-photos .vehicleBanner-video-button{
            background: rgba(255,255,255,1);
            height: unset;
            padding: 20px;
            &:hover{
                background: rgba(255,255,255,0.85);
            }
        }
        .vehicleBanner-photos .vehicleBanner-video-button icon{
            color: $utilColor;
            padding-right: 5px;
        }
        .payment-summary-section .listing-tile-all-price .disclaimer-price.is-light{
            color: $labelColor;
        }
        .preview-photo-wrapper .preview-photo-text{
            background: $utilColor;
        }
        .TradeInTile--content-official-title .sr-button-1{
            background: $utilColor;
        }
        .VehicleDisclaimersContent p{
            color: $labelColor;
        }
        .car-equipments_summary .features-bloc{
            color: $labelColor;
        }
        .paymentCalculator__payment .is-util{
            color: $lightColor;
        }
        .paymentCalculator__buttons #calculator-toggle-button.sr-button-1{
            background: $labelColor;
        }
        .payment-calculator-wrapper.is-open .paymentCalculator__buttons #calculator-toggle-button.sr-button-1{
            background: $lightColor;
            color: $utilColor;
        }
        .paymentCalculator__rebates--term.is-light .icon-promo2.is-util{
            color: $lightColor;
        }
        .purchase-methods-tab__tabs.sr-calculator-tabs li{
            border-color: rgba(255,255,255,0.5);
        }
        .purchase-methods-tab__tabs.sr-calculator-tabs li.current{
            border-color: $lightColor;
        }
        .purchase-methods-cash .purchase-detail-line.pdsf_total{
            border: 2px solid;
        }
        .calculatorColumn .sr-link,
        .sr-paymentCalculator-EmphasisColor .is-util{
            color: $lightColor;
        }
        .new-customize-payment,
        .calculator-cash-down,
        .section-distance,
        .calculator-done{
            border-color: $lightColor;
        }
        .sr-paymentCalculator__calculatorTerm--background{
            background-color: $labelColor;
            color: $lightColor;
        }
        .sr-paymentCalculator__calculatorTerm--background:not(.selected) .price.is-util{
            color: #ffffff !important;
        }
        .sr-paymentCalculator__calculatorTerm--background.selected .is-light,
        .sr-paymentCalculator__calculatorTerm--background.selected .sr-mediumColor{
            color: $utilColor;
        }
        .sr-paymentCalculator__calculatorTerm--background .term{
            background-color: $lightColor;
            color: $utilColor;
        }
        .sr-paymentCalculator__calculatorTerm--background.selected .term{
            background-color: $utilColor;
            color: $lightColor;
        }
        .sr-paymentCalculator-Panelbackground .sr-button-1{
            background: $lightColor;
            color: $utilColor;
        }
        .sr-paymentCalculator-dropdown .is-focused .Select-control,
        .sr-paymentCalculator-dropdown .Select-control,
        .sr-paymentCalculator-dropdown .Select-option{
            background-color: $labelColor !important;
        }
        .message-handler.warning{
            background-color: $labelColor;
        }
        .selection-year-bloc__selectButton .sr-button-outline-2{
            background-color: #000000;
            color: #ffffff;
            border-color: #000000;
        }
        .selection-year-bloc__selectButton .sr-button-outline-2.is-hover{
            background-color: #ffffff;
            color: #000000;
            border-color: #ffffff;
        }
        .sr-sidebarsummary .vehicle-specs li.is-light{
            color: #999999;
        }
        .widget-garage .vehicle-title-specs .vehicle-specs li.is-light,
        .widget-garage #stockNumber.is-light{
            color: #000000;
        }
        .showroom-vdp-used-features-vehicle-info,
        .showroom-vdp-used-features-vehicle-info-title,
        .showroom-vdp-used-features-vehicle-info-value,
        .showroom-vdp-used-features-vehicle-info-fuel-info,
        .showroom-vdp-used-features-vehicle-info-fuel-name,
        .financing-section.tile-payment-info .tile-payment-rate,
        .financing-section.tile-payment-info .tile-frequency,
        .vehicle-overview__vehicle-title .trim,
        .financing-section.tile-payment-info .tile-payment-detail.small,
        .financing-section.tile-payment-info .tile-payment-detail,
        .financing-section.tile-payment-info .tile-payment-rate .frequency-highlight,
        .VehicleDisclaimersContent p,
        .showroom-vdp-used-options .showroom-vdp-used-options-list .showroom-vdp-used-options-item,
        .showroom-vdp-used-certified-zone .certified-text.is-light
        {
            color: #000000;
        }
        .vehicleBanner-personsViewed .amount.is-price{
            color: #ffffff;
        }
        &.-hiddenBuyOnline {
            .showroom-vdp-used,
            .showroom-vdp-new{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        &.-hiddenCatalogBuyOnline {
            .showroom-build-and-price__content{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        .payment-calculator-wrapper{
            z-index: auto;
        }

        &.trade-is-on-top{
            .listing-used-car-search>div:last-child {
                display: flex;
                flex-direction: column;
                #instant-estimate-bar-wrapper{
                    order: 1;
                    margin-top: 0 !important;
                }
                .listing-tiles{
                    order: 2;
                }
                .listing-used-button-loading{
                    order: 3;
                }
            }
        }
        // **********************
        // Header B&B
        // **********************
        @include headerBuildBuy;

        // **********************
        // Barre de progression
        // **********************
        @include progressBar;

        // **********************
        // Formulaires
        // **********************
        @include forms;

        // **********************
        // Par sections
        // **********************
        @include paymentCalculator;
        @include checkout;
        @include sidebarsummary;
        @include login;
        @include garage;
        @include protections;

        @include titles;
        @include buttons;
        @include links;
        @include generics;
        @include baseline;
    }
}

@include breakpoint(desktop){
    .widget-sr{
        &.makes__lexus{
            .payment-calculator-wrapper{
                z-index: 9;
            }
        }
    }
}